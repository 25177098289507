/* Contenedor principal de Contactanos */
.contact-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    background-color: #000000;
    min-height: 100vh;
}

/* Estilo para el formulario */
.contact-form {
    flex: 1;
    margin-right: 50px;
}

.contact-form h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #ffffff;
}

/* Estilo para los grupos de inputs */
.input-group {
    margin-bottom: 20px;
}

.input-group label {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #ffffff;
    display: block;
    margin-bottom: 5px;
}

.input-group .required {
    font-size: 0.9rem;
    color: #3d3939;
}

/* Estilo para los inputs de nombre */
.name-inputs {
    display: flex;
    gap: 15px;
}

.name-inputs input {
    flex: 1;
    padding: 10px;
    font-size: 1rem;
    border: 2px solid #333;
    border-radius: 20px;
    outline: none;
}

/* Estilo para los campos de correo y mensaje */
input[type="email"], textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 2px solid #3d3a3a;
    border-radius: 20px;
    outline: none;
}

textarea {
    height: 150px;
    resize: none;
}

/* Estilo para el botón de envío */
.send-button {
    background-color: rgb(248, 243, 243);
    color: rgb(0, 0, 0);
    border: none;
    padding: 15px 30px;
    font-size: 1rem;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.send-button:hover {
    background-color: #333;
}

/* Estilo para la imagen al lado del formulario */
.contact-image {
    flex: 1;
    text-align: center;
}

.contact-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}
