/* Estilo base para la barra de navegación */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 40px; 
    position: fixed;
    top: 0;
    width: 95%;
    background-color: transparent; 
    z-index: 1000;
}

/* Estilo del logo */
.navbar-logo {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

/* Estilo de los enlaces de la navbar */
.navbar-links {
    display: flex;
    gap: 20px; /* Reducimos el espacio entre los enlaces */
}

.navbar-links a {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;
}

.navbar-links a:hover {
    color: #aaa; 
}


.navbar-links a {
    white-space: nowrap; /* Evita que el texto se corte o baje de línea */
}
