/* Importar la fuente Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

/* Aplicar Poppins a toda la sección */
.servicios {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    background-color: rgb(0, 0, 0); /* Fondo oscuro */
    padding: 50px 0; /* Mayor separación arriba y abajo */
    text-align: center; /* Centrar todo el contenido */
}

.servti {
    font-size: 36px;
    color: white;
    margin-bottom: 50px;
}

.serviA {
    display: flex;
    justify-content: center; /* Centra las burbujas horizontalmente */
    gap: 50px; /* Mayor espacio entre los servicios */
    align-items: center; /* Alinea verticalmente las burbujas y el texto */
}

.servicios1, .servicios2, .servicios3 {
    flex: 1;
    max-width: 280px; /* Limita el ancho de cada tarjeta */
    text-align: center; /* Centrar el texto */
}

.servicios1 img, .servicios2 img, .servicios3 img {
    width: 220px;
    height: 220px;
    border-radius: 50%; /* Imágenes circulares */
    object-fit: cover; /* Ajusta la imagen dentro del círculo */
    margin-bottom: 20px;
}

.servicios1 h2, .servicios2 h2, .servicios3 h2 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase; /* Texto en mayúsculas */
    color: white;
    margin-top: 15px;
    margin-bottom: 10px;
    position: relative;
}

.servicios1 h2::after, .servicios2 h2::after, .servicios3 h2::after {
    content: '';
    display: block;
    width: 40px; /* Longitud de la línea debajo del texto */
    height: 2px;
    background-color: white; /* Color de la línea */
    margin: 10px auto 0; /* Centra la línea debajo del texto */
}

/* Estilo de los párrafos */
.servicios1 p, .servicios2 p, .servicios3 p {
    font-size: 16px;
    color: rgb(0, 0, 0); /* Color de texto gris */
    line-height: 1.6;
    margin-bottom: 20px;
}

/* Botón de contacto similar al estilo del inicio */
.contacto-btn {
    margin-top: 40px;
    padding: 10px 30px;
    background-color: #333;
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    text-transform: uppercase;
    transition: background-color 0.3s ease, transform 0.3s ease;
    width: 200px; /* Aumentar el ancho del botón */
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.contacto-btn:hover {
    background-color: #555;
    transform: scale(1.05); /* Efecto de hover que aumenta el tamaño del botón */
}
