.proyec_glo {
    background-color: #0000008c; /* Mantiene el fondo oscuro */
    padding: 40px 0;
    max-width: 1300px;
    margin: 0 auto; /* Centrar todo el contenido */
}

.slick-slider {
    position: relative;
    width: 70%; /* Reducimos el ancho para que no ocupe toda la pantalla */
    margin: 0 auto; /* Centrar el carrusel */
}

.slick-list {
    overflow: scroll;
}

.slick-track {
    display: flex;
}

/* Ajuste de las cajas (tarjetas) */
.poyect1, .poyect2, .poyect3 {
    flex: 1;
    background-color: white;
    border: 1px solid #ddd;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 30px; /* Bordes redondeados más pequeños */
    margin: 30px 10px; /* Mayor espacio entre las tarjetas */
    min-height: 400px; /* Altura mínima uniforme */
    max-width: 800px; /* Limita el ancho de las tarjetas */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Alinear la imagen en el centro de la tarjeta */
.poyect1 img, .poyect2 img, .poyect3 img {
    max-width: 80%;
    height: auto;
    object-fit: contain; /* Mantener la imagen sin deformación */
    margin: 0 auto 20px auto; /* Centrar la imagen */
    border-radius: 8px;
}

/* Títulos de los proyectos */
.poyect1 h3, .poyect2 h3, .poyect3 h3 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: black;
}

/* Descripción del proyecto */
.poyect1 p, .poyect2 p, .poyect3 p {
    font-size: 14px;
    color: #333;
    line-height: 1.6;
    margin-bottom: 20px;
}

/* Botones de enlaces en los proyectos */
.proyectslink {
    border: 2px solid black;
    padding: 10px 20px;
    text-decoration: none;
    color: black;
    font-weight: 600;
    display: inline-block;
    margin-top: auto;
}

.proyectslink:hover {
    background-color: black;
    color: white;
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Estilo de los puntos del carrusel (dots) */
.slick-dots {
    bottom: -30px;
}

.slick-dots li button:before {
    font-size: 12px;
    color: #fff;
}

.slick-dots li.slick-active button:before {
    color: #000;
}
