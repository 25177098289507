body, html {
    margin: 0;
    padding: 0;
    height:100%;
    background-color: #0000001c; /* Fondo negro */
    color: #fff; /* Texto blanco */
  }
  
  .founders-container {
    min-height: 80vh;
    text-align: center;
    padding: 40px 40px;
    background-color: #000;
  }
  
  .founders-container h2 {
    color: #fff;
    text-align: center; /* Centra el título */
    margin-bottom: 60px;
  }
  
  .founders-grid {
    display: flex;
    justify-content: space-around;
    align-items: stretch; /* Asegura que todas las tarjetas tengan la misma altura */
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .founder {
    width: 250px;
    margin: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; /* Asegura que los botones estén al final */
    min-height: 400px; /* Establece una altura mínima para todas las tarjetas */
  }
  
  .founder-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover; /* Mantiene la proporción sin distorsionar la imagen */
    margin-bottom: 15px;
  }
  
  .founder h3 {
    color: #fff; /* Color del nombre del fundador */
  }
  
  .founder p {
    color: #ccc; /* Color del texto de la descripción */
    flex-grow: 1; /* Hace que el texto ocupe el espacio intermedio para que los botones se alineen */
  }
  
  .linkedin-btn {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #fff; /* Botón con fondo blanco */
    color: #000; /* Texto del botón en negro */
    border-radius: 20px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .linkedin-btn:hover {
    background-color: #0077b5;
    color: #fff;
  }
  