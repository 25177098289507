#video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.inicio-background {
  background-color: rgba(0, 0, 0, 0.815);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  position: relative;
}

/* Centra el contenido */
.inicio-content {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.inicio-logo {
  width: 340px; 
  margin-bottom: -60px; /* Reducí el margen inferior */
  animation: zoomInOut 4s infinite ease-in-out;
}

/* Título más cercano al logo */
.inicio-title {
  font-family: 'Poppins', sans-serif;
  font-size: 2.5rem;
  margin-bottom: 35px; 
}

/* Contenedor de los botones */
.button-container {
  display: flex;
  justify-content: center;
  gap: 15px;
}

/* Botones */
.inicio-button {
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  font-size: 1rem;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.inicio-button:hover {
  background-color: #555;
  transform: scale(1.05);
}

/* Botón principal */
.main-button {
  background-color: #333;
}

/* Animación para el logo */
@keyframes zoomInOut {
  0%, 100% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.1);
  }
}
