.nosotros_a {
    background-color: #030303;
    display: flex;
    justify-content: center; /* Centra horizontalmente todo el contenido */
    align-items: center; /* Centra verticalmente todo el contenido */
    height: 100vh; /* Asegura que el recuadro cubra toda la pantalla */
    padding: 20px;
    font-family: 'Poppins', sans-serif;
    color: white; /* Texto en blanco */
    position: relative;
    overflow: hidden; /* Asegura que la imagen no sobresalga de la pantalla */
}

/* Imagen a la derecha */
.side-image {
    position: absolute;
    width: 800px; /* Ajusta el tamaño de la imagen */
    height: 800px;
    object-fit: cover;
    opacity: 0.2; /* Imagen semitransparente para no distraer del texto */
    z-index: 0; /* Asegura que la imagen esté detrás del contenido */
    right: 350px; /* Ajusta la posición de la imagen a la derecha */
    top: -10%; /* Centra la imagen verticalmente */
    transform: translateY(-50%); /* Ajusta para que esté completamente en la mitad */
    animation: rotate 10s infinite linear; /* Animación de rotación */
}

.side-image2 {
    position: absolute;
    width: 800px; /* Ajusta el tamaño de la imagen */
    height: 800px;
    object-fit: cover;
    opacity: 0.1; /* Imagen semitransparente para no distraer del texto */
    z-index: 0; /* Asegura que la imagen esté detrás del contenido */
    left: 300px; /* Posiciona la segunda imagen a la izquierda */
    top: -12%; /* Centra la imagen verticalmente */
    transform: translateY(65%); /* Ajusta para que esté completamente en la mitad */
    animation: rotate 8s infinite linear; /* Animación de rotación */
}

/* El texto y los botones ahora estarán centrados */
.nosotros_c {
    flex: 1;
    text-align: center; /* Alinea el texto al centro */
    z-index: 1; /* Asegura que el texto esté por encima de la imagen */
    color: white;
    max-width: 600px; /* Limita el ancho del texto */
    margin: 0 auto; /* Centra el contenido horizontalmente */
}

/* Botones centrados */
.lista_container {
    list-style-type: none;
    padding: 0;
    margin-top: 30px;
    display: flex;
    justify-content: center; /* Alinea los botones en el centro */
    gap: 20px; /* Espacio entre los botones */
}

.lista_container li {
    padding: 0;
    width: 250px; /* Ajusta el tamaño de los botones */
}

.lista_container a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: white;
    padding: 20px;
    border: 2px solid white;
    border-radius: 15px;
    background-color: black;
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.lista_container a:hover {
    background-color: white;
    color: #030303;
    transform: translateY(-5px); /* Añade un pequeño desplazamiento en hover */
}

/* Animación de rotación */
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
